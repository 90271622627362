<template>
  <div
    v-if="showAdminSection && isStandalone"
    class="menu-section-label"
  >
    {{ $gettext("Account") }}
  </div>
  <router-link
    class="dropdown-item"
    :to="{ name: 'AccountEditForSelf' }"
  >
    {{ $gettext("Edit my account") }}
  </router-link>

  <router-link
    class="dropdown-item"
    :to="{ name: 'ChangeOwnPassword' }"
  >
    {{ $gettext("Change my password") }}
  </router-link>

  <router-link
    class="dropdown-item"
    :to="{ name: 'LanguagePreferences' }"
  >
    {{ $gettext("Set language") }}
  </router-link>

  <!-- <hr class="dropdown-divider" /> -->

  <router-link
    class="dropdown-item"
    :to="{ name: 'Logout' }"
  >
    {{ $gettext("Log out") }}
  </router-link>
  <template v-if="showAdminSection">
    <div class="menu-section-label">
      Administration
    </div>
    <router-link
      class="dropdown-item"
      :to="{ name: 'ProjectCreate' }"
    >
      Add a new project
    </router-link>
  </template>
</template>
<script setup>
  defineProps({
    isStandalone: {
      type: Boolean,
      default: false,
    },
  })
  import { ref } from "vue"
  import { getAccountInfo } from "@/utils/GlobalState"
  import { isEmpty } from "@/utils/Utilities"
  import { updateCachedAccountInfo } from "@/services/Account"
  const showAdminSection = ref(false)

  let accountInfo = getAccountInfo()
  if (!isEmpty(accountInfo)) {
    showAdminSection.value = accountInfo.canAddProjects
  }
  else {
    // This should get called upon login, before account info has been
    // queried and cached.
    updateCachedAccountInfo().then(() => {
      accountInfo = getAccountInfo()
      showAdminSection.value = accountInfo?.canAddProjects
    })
  }
</script>
