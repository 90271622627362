import { isUserLoggedIn } from "@/utils/GlobalState"
import { checkSession } from "@/utils/Session"
import { createRouter, createWebHashHistory } from "vue-router"

// Core initial
const Login = () => import("../views/Login.vue")
const SiteList = () => import("../views/sites/List.vue")
const AgreementDocumentList = () => import("../views/accounts/AgreementDocumentList.vue")
const Logout = () => import("../views/accounts/Logout.vue")

// Account
const ComingSoon = () => import("../views/ComingSoon.vue")
const ConnectivityRequired = () => import("../views/ConnectivityRequired.vue")
const AccountEdit = () => import("../views/accounts/Edit.vue")
const ChangeOwnPassword = () => import("../views/accounts/ChangeOwnPassword.vue")
const LanguagePreferences = () => import("../views/accounts/LanguagePreferences.vue")
const NewPinForm = () => import("../views/accounts/NewPinForm.vue")
const ReenterPinForm = () => import("../views/accounts/ReenterPinForm.vue")
const FeedbackForm = () => import("../views/FeedbackForm.vue")
const ForgotPasswordForm = () => import("../views/accounts/ForgotPasswordForm.vue")
const ResetPasswordForm = () => import("../views/accounts/ResetPasswordForm.vue")

// Core site-and-child
const ChildCarePlan = () => import("../views/children/CarePlan.vue")
const ChildConfirmation = () => import("../views/children/Confirmation.vue")
const ChildCreateOrUpdate = () => import("../views/children/CreateOrUpdate.vue")
const ChildDischarge = () => import("../views/children/Discharge.vue")
const ChildJustAdded = () => import("../views/children/JustAdded.vue")
const ChildList = () => import("../views/children/List.vue")
const SiteAccounts = () => import("../views/sites/Accounts.vue")
const SiteAssessmentCalendar = () => import("../views/sites/AssessmentCalendar.vue")
const SiteHome = () => import("../views/sites/Home.vue")

// Site administration
const AccountCreateForSite = () => import("../views/accounts/CreateForSite.vue")
const ChangeOthersPassword = () => import("../views/accounts/ChangeOthersPassword.vue")
const SiteCreateOrUpdate = () => import("../views/sites/CreateOrUpdate.vue")
const SiteDisableTrainingMode = () => import("../views/sites/DisableTrainingMode.vue")
const SiteManage = () => import("../views/sites/Manage.vue")
const SiteSettings = () => import("../views/sites/Settings.vue")
const ClinicalReportReferrals = () => import("../views/sites/ClinicalReportReferrals.vue")

// Growth
const GrowthAssessmentNotes = () => import("../views/growth/AssessmentNotes.vue")
const GrowthDateOfAssessment = () => import("../views/growth/DateOfAssessment.vue")
const GrowthRecommendations = () => import("../views/growth/Recommendations.vue")
const GrowthRecommendationsAdvanced = () => import("../views/growth/RecommendationsAdvanced.vue")
const GrowthStandForm = () => import("../views/growth/StandForm.vue")
const MeasurementHeadSize = () => import("../views/growth/MeasurementHeadSize.vue")
const MeasurementLength = () => import("../views/growth/MeasurementLength.vue")
const MeasurementMuac = () => import("../views/growth/MeasurementMuac.vue")
const MeasurementWeight = () => import("../views/growth/MeasurementWeight.vue")
const GrowthFormMenu = () => import("../views/growth/FormMenu.vue")
const NeedGrowthAssessmentList = () => import("../views/growth/NeedGrowthAssessmentList.vue")

// Project
const ProjectCreateOrUpdate = () => import("../views/projects/CreateOrUpdate.vue")
const ProjectHome = () => import("../views/projects/Home.vue")
const ProjectAccounts = () => import("../views/projects/Accounts.vue")
const AccountCreateForProject = () => import("../views/accounts/CreateForProject.vue")
const PrepareForOffline = () => import("../views/sites/PrepareForOffline.vue")
const PendingUploads = () => import("../views/PendingUploads.vue")

// Intro
const ResourceLibrary = () => import("../views/library/ResourceLibrary.vue")
const IntroSlide1 = () => import("../views/library/introduction/IntroSlide1.vue")
const IntroSlide2 = () => import("../views/library/introduction/IntroSlide2.vue")
const IntroSlide3 = () => import("../views/library/introduction/IntroSlide3.vue")
const IntroSlide4 = () => import("../views/library/introduction/IntroSlide4.vue")

// The growth resource library pages are split up over the growth views.
const MeasureArmSize = () => import("../views/library/MeasureArmSize.vue")
const MeasureHeadSize = () => import("../views/library/MeasureHeadSize.vue")
const MeasureHeight = () => import("../views/library/MeasureHeight.vue")
const MeasureLength = () => import("../views/library/MeasureLength.vue")
const MeasureWeightAdultChild = () => import("../views/library/MeasureWeightAdultChild.vue")
const MeasureWeightChild = () => import("../views/library/MeasureWeightChild.vue")
const MeasureWeightInfant = () => import("../views/library/MeasureWeightInfant.vue")

// Other library content
const GuideToFoodTextures = () => import("../views/library/GuideToFoodTextures.vue")
const EmptySpoonTechnique = () => import("../views/library/EmptySpoonTechnique.vue")
const LateralSpoonTechnique = () => import("../views/library/LateralSpoonTechnique.vue")
const MeasureHemoglobin = () => import("../views/library/MeasureHemoglobin.vue")

// Anemia
const NeedAnemiaAssessmentList = () => import("../views/anemia/NeedAnemiaAssessmentList.vue")
const AnemiaPreface = () => import("../views/anemia/Preface.vue")
const AnemiaHemoglobinForm = () => import("../views/anemia/HemoglobinForm.vue")
const AnemiaRecommendations = () => import("../views/anemia/Recommendations.vue")

// Feeding screening
const FeedingScreeningDateOfAssessment = () => import("../views/feeding/DateOfAssessment.vue")
const FeedingScreeningNotes = () => import("../views/feeding/NotesForm.vue")
const FeedingScreening = () => import("../views/feeding/FeedingScreening.vue")
const FeedingScreeningRecommendations = () => import("../views/feeding/ScreeningRecommendations.vue")
const NeedFeedingScreeningList = () => import("../views/feeding/NeedFeedingScreeningList.vue")

// Best Practices
const PracticesDateOfAssessment = () => import("../views/practices/DateOfAssessment.vue")
const PracticesNotesForm = () => import("../views/practices/NotesForm.vue")
const PracticesToolsForm = () => import("../views/practices/ToolsForm.vue")
const PracticesTexturesForm = () => import("../views/practices/TexturesForm.vue")
const PracticesFeederForm = () => import("../views/practices/FeederForm.vue")
const PracticesFormMenu = () => import("../views/practices/FormMenu.vue")
const BreastfeedingForm = () => import("../views/practices/BreastfeedingForm.vue")
const ResponsiveFeedingForm = () => import("../views/practices/ResponsiveFeedingForm.vue")
const OtherFeedingPracticesForm = () => import("../views/practices/OtherFeedingPracticesForm.vue")
const DietQualityForm = () => import("../views/practices/DietQualityForm.vue")
const PracticesRecommendations = () => import("../views/practices/Recommendations.vue")
const NeedBestPracticesList = () => import("../views/practices/NeedBestPracticesList.vue")

// Developmental screening
const DevelopmentalScreeningDateOfAssessment = () => import("../views/developmentalscreening/DateOfAssessment.vue")
const DevelopmentalScreeningAssessmentNotes = () => import("../views/developmentalscreening/AssessmentNotes.vue")
const DevelopmentalScreeningFormMenu = () => import("../views/developmentalscreening/FormMenu.vue")
const DevelopmentalScreeningBehaviorQuestions = () => import("../views/developmentalscreening/BehaviorQuestions.vue")
const DevelopmentalScreeningDevelopmentQuestions = () => import("../views/developmentalscreening/DevelopmentQuestions.vue")
const DevelopmentalScreeningEmotionalChangesQuestions = () => import("../views/developmentalscreening/EmotionalChangesQuestions.vue")
const DevelopmentalScreeningFamilyQuestions = () => import("../views/developmentalscreening/FamilyQuestions.vue")
const DevelopmentalScreeningWagDevelopmentQuestions = () => import("../views/developmentalscreening/WagDevelopmentQuestions.vue")
const DevelopmentalScreeningWagBehaviorQuestions = () => import("../views/developmentalscreening/WagBehaviorQuestions.vue")
const DevelopmentalScreeningRecommendations = () => import("../views/developmentalscreening/Recommendations.vue")
const DevelopmentalScreeningResponses = () => import("../views/developmentalscreening/Responses.vue")
const NeedDevelopmentalScreeningList = () => import("../views/developmentalscreening/NeedDevelopmentalScreeningList.vue")

// Documentation
const DocumentationHome = () => import("../views/documentation/Home.vue")
const DocumentationWhatIsCmiPlus = () => import("../views/documentation/WhatIsCmiPlus.vue")
const DocumentationOfflineUsageAbout = () => import("../views/documentation/OfflineUsageAbout.vue")
const DocumentationOfflineUsagePrepare = () => import("../views/documentation/OfflineUsagePrepare.vue")

// Reports
const DemographicReport = () => import("../views/reports/DemographicReport.vue")
const MalnutritionReport = () => import("../views/reports/MalnutritionReport.vue")

// Site Visit Reports
const SiteVisitReportListForSite = () => import("../views/sitevisitreports/ListForSite.vue")
const SiteVisitReportListForProject = () => import("../views/sitevisitreports/ListForProject.vue")
const SiteVisitReportCreateOrUpdate = () => import("../views/sitevisitreports/CreateOrUpdate.vue")
const SiteVisitReportDetail = () => import("../views/sitevisitreports/Detail.vue")

// For certain pages, if we know for sure that user is offline (bc the browser
// says so), redirect them to a generic page telling them they need to be online.
function connectivityRequired() {
  if (!navigator.onLine) {
    return { name: "ConnectivityRequired" }
  }
}

const routes = [
  {
    path: "/",
    name: "HomeRedirect",
    redirect: () => {
      const userIsLoggedIn = isUserLoggedIn()
      return { name: userIsLoggedIn.value ? "SiteList" : "Login" }
    },
  },
  { path: "/connectivity-required", name: "ConnectivityRequired", component: ConnectivityRequired },

  // Account-related
  { path: "/login", name: "Login", component: Login, beforeEnter: [connectivityRequired] },
  { path: "/forgot-password", name: "ForgotPasswordForm", component: ForgotPasswordForm, beforeEnter: [connectivityRequired] },
  { path: "/reset-password/:token/", name: "ResetPasswordForm", component: ResetPasswordForm, beforeEnter: [connectivityRequired] },
  { path: "/logout", name: "Logout", component: Logout },
  { path: "/account/language", name: "LanguagePreferences", component: LanguagePreferences },
  { path: "/account/edit", name: "AccountEditForSelf", component: AccountEdit, beforeEnter: [connectivityRequired, checkSession] },
  { path: "/account/change-password", name: "ChangeOwnPassword", component: ChangeOwnPassword, beforeEnter: [connectivityRequired, checkSession] },
  { path: "/feedback", name: "FeedbackForm", component: FeedbackForm },
  { path: "/pin/new", name: "NewPinForm", component: NewPinForm },
  { path: "/pin/confirm", name: "ReenterPinForm", component: ReenterPinForm },

  { path: "/go-offline", name: "PrepareForOffline", component: PrepareForOffline, beforeEnter: [connectivityRequired, checkSession] },
  { path: "/pending-uploads", name: "PendingUploads", component: PendingUploads, beforeEnter: checkSession },

  // Project-related
  { path: "/project/new", name: "ProjectCreate", component: ProjectCreateOrUpdate, beforeEnter: [connectivityRequired, checkSession] },
  { path: "/project/:projectCmiId(\\d+)/", name: "ProjectHome", component: ProjectHome, beforeEnter: checkSession },
  { path: "/project/:projectCmiId(\\d+)/accounts/", name: "ProjectAccounts", component: ProjectAccounts, beforeEnter: checkSession },
  { path: "/project/:projectCmiId(\\d+)/accounts/new", name: "AccountCreateForProject", component: AccountCreateForProject, beforeEnter: checkSession },
  { path: "/project/:projectCmiId(\\d+)/update", name: "ProjectUpdate", component: ProjectCreateOrUpdate, beforeEnter: [connectivityRequired, checkSession] },

  // Site-related
  { path: "/site", name: "SiteList", component: SiteList, beforeEnter: checkSession },
  { path: "/site/:siteCmiId(\\d+)", name: "SiteHome", component: SiteHome, beforeEnter: checkSession },
  { path: "/site/:siteCmiId(\\d+)/manage/", name: "SiteManage", component: SiteManage, beforeEnter: checkSession },
  { path: "/site/:siteCmiId(\\d+)/accounts/", name: "SiteAccounts", component: SiteAccounts, beforeEnter: checkSession },
  {
    path: "/site/:siteCmiId(\\d+)/accounts/:accountCmiId(\\d+)/edit",
    name: "AccountEditForOther",
    component: AccountEdit,
    beforeEnter: [connectivityRequired, checkSession],
  },
  {
    path: "/site/:siteCmiId(\\d+)/accounts/:accountCmiId(\\d+)/change-password",
    name: "ChangeOthersPassword",
    component: ChangeOthersPassword,
    beforeEnter: [connectivityRequired, checkSession],
  },
  {
    path: "/site/:siteCmiId(\\d+)/accounts/new",
    name: "AccountCreateForSite",
    component: AccountCreateForSite,
    beforeEnter: [connectivityRequired, checkSession],
  },
  {
    path: "/agreements",
    name: "AgreementDocumentList",
    component: AgreementDocumentList,
    beforeEnter: [connectivityRequired, checkSession],
  },
  { path: "/project/:projectCmiId(\\d+)/site/new/", name: "SiteCreate", component: SiteCreateOrUpdate, beforeEnter: [connectivityRequired, checkSession] },
  { path: "/site/:siteCmiId(\\d+)/edit/", name: "SiteEdit", component: SiteCreateOrUpdate, beforeEnter: [connectivityRequired, checkSession] },
  { path: "/site/:siteCmiId(\\d+)/settings/", name: "SiteSettings", component: SiteSettings, beforeEnter: [connectivityRequired, checkSession] },
  { path: "/site/:siteCmiId(\\d+)/clinical-reports/referrals/", name: "ClinicalReportReferrals", component: ClinicalReportReferrals, beforeEnter: checkSession },
  {
    path: "/site/:siteCmiId(\\d+)/training-mode/",
    name: "SiteDisableTrainingMode",
    component: SiteDisableTrainingMode,
    beforeEnter: [connectivityRequired, checkSession]
  },
  { path: "/site/:siteCmiId(\\d+)/children", name: "ChildList", component: ChildList, beforeEnter: checkSession },
  {
    path: "/site/:siteCmiId(\\d+)/calendar",
    name: "SiteAssessmentCalendar",
    component: SiteAssessmentCalendar,
    beforeEnter: checkSession,
  },

  // Child-related
  { path: "/site/:siteCmiId(\\d+)/child/new", name: "ChildCreate", component: ChildCreateOrUpdate },
  {
    path: "/site/:siteCmiId(\\d+)/child/:childId(\\d+)/update",
    name: "ChildUpdate",
    component: ChildCreateOrUpdate,
    beforeEnter: checkSession,
  },
  {
    path: "/site/:siteCmiId(\\d+)/child/:childId(\\d+)/discharge",
    name: "ChildDischarge",
    component: ChildDischarge,
    beforeEnter: checkSession,
  },
  {
    path: "/site/:siteCmiId(\\d+)/child/:childId(\\d+)/new",
    name: "ChildJustAdded",
    component: ChildJustAdded,
    beforeEnter: checkSession,
  },
  {
    // assessmentType must be one of: g, a, m, d
    path: "/site/:siteCmiId(\\d+)/child/:childId(\\d+)/confirmation/:assessmentType(\\w)",
    name: "ChildConfirmation",
    component: ChildConfirmation,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/care-plan",
    name: "ChildCarePlan",
    component: ChildCarePlan,
    beforeEnter: checkSession,
  },

  // Resource Library
  {
    path: "/library",
    name: "ResourceLibrary",
    component: ResourceLibrary,
  },
  {
    path: "/library/length",
    name: "MeasureLength",
    component: MeasureLength,
  },
  {
    path: "/library/arm-size",
    name: "MeasureArmSize",
    component: MeasureArmSize,
  },
  {
    path: "/library/head-size",
    name: "MeasureHeadSize",
    component: MeasureHeadSize,
  },
  {
    path: "/library/height",
    name: "MeasureHeight",
    component: MeasureHeight,
  },
  {
    path: "/library/weight-adult-child",
    name: "MeasureWeightAdultChild",
    component: MeasureWeightAdultChild,
  },
  {
    path: "/library/weight-child",
    name: "MeasureWeightChild",
    component: MeasureWeightChild,
  },
  {
    path: "/library/weight-infant",
    name: "MeasureWeightInfant",
    component: MeasureWeightInfant,
  },
  {
    path: "/library/food-textures",
    name: "GuideToFoodTextures",
    component: GuideToFoodTextures,
  },
  {
    path: "/library/empty-spoon",
    name: "EmptySpoonTechnique",
    component: EmptySpoonTechnique,
  },
  {
    path: "/library/lateral-spoon",
    name: "LateralSpoonTechnique",
    component: LateralSpoonTechnique,
  },
  {
    path: "/library/measure-hemoglobin",
    name: "MeasureHemoglobin",
    component: MeasureHemoglobin,
  },

  // Introduction slides
  {
    path: "/intro/1",
    name: "IntroSlide1",
    component: IntroSlide1,
  },
  {
    path: "/intro/2",
    name: "IntroSlide2",
    component: IntroSlide2,
  },
  {
    path: "/intro/3",
    name: "IntroSlide3",
    component: IntroSlide3,
  },
  {
    path: "/intro/4",
    name: "IntroSlide4",
    component: IntroSlide4,
  },
  {
    path: "/coming-soon",
    name: "ComingSoon",
    component: ComingSoon,
  },

  // Developmental screening
  {
    path: "/child/:childId(\\d+)/developmental-screening/:assessmentId(\\d+)?/",
    name: "DevelopmentalScreeningDateOfAssessment",
    component: DevelopmentalScreeningDateOfAssessment,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/developmental-screening/:assessmentId(\\d+)/notes/",
    name: "DevelopmentalScreeningAssessmentNotes",
    component: DevelopmentalScreeningAssessmentNotes,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/developmental-screening/:assessmentId(\\d+)/home",
    name: "DevelopmentalScreeningFormMenu",
    component: DevelopmentalScreeningFormMenu,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/developmental-screening/:assessmentId(\\d+)/dm",
    name: "DevelopmentalScreeningDevelopmentQuestions",
    component: DevelopmentalScreeningDevelopmentQuestions,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/developmental-screening/:assessmentId(\\d+)/b",
    name: "DevelopmentalScreeningBehaviorQuestions",
    component: DevelopmentalScreeningBehaviorQuestions,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/developmental-screening/:assessmentId(\\d+)/f",
    name: "DevelopmentalScreeningFamilyQuestions",
    component: DevelopmentalScreeningFamilyQuestions,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/developmental-screening/:assessmentId(\\d+)/ec",
    name: "DevelopmentalScreeningEmotionalChangesQuestions",
    component: DevelopmentalScreeningEmotionalChangesQuestions,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/developmental-screening/:assessmentId(\\d+)/wd",
    name: "DevelopmentalScreeningWagDevelopmentQuestions",
    component: DevelopmentalScreeningWagDevelopmentQuestions,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/developmental-screening/:assessmentId(\\d+)/wb",
    name: "DevelopmentalScreeningWagBehaviorQuestions",
    component: DevelopmentalScreeningWagBehaviorQuestions,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/developmental-screening/:assessmentId(\\d+)/recommendations/",
    name: "DevelopmentalScreeningRecommendations",
    component: DevelopmentalScreeningRecommendations,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/developmental-screening/:assessmentId(\\d+)/responses/",
    name: "DevelopmentalScreeningResponses",
    component: DevelopmentalScreeningResponses,
    beforeEnter: checkSession,
  },
  {
    path: "/site/:siteCmiId(\\d+)/children/needing-developmental-screening/",
    name: "NeedDevelopmentalScreeningList",
    component: NeedDevelopmentalScreeningList,
    beforeEnter: checkSession,
  },

  // Growth
  {
    path: "/site/:siteCmiId(\\d+)/children/needing-growth",
    name: "NeedGrowthAssessmentList",
    component: NeedGrowthAssessmentList,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/growth/:assessmentId(\\d+)?/1",
    name: "GrowthDateOfAssessment",
    component: GrowthDateOfAssessment,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/growth/:assessmentId(\\d+)/2/",
    name: "GrowthStandForm",
    component: GrowthStandForm,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/growth/:assessmentId(\\d+)/3/",
    name: "GrowthAssessmentNotes",
    component: GrowthAssessmentNotes,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/growth/:assessmentId(\\d+)/measurements/",
    name: "GrowthFormMenu",
    component: GrowthFormMenu,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/growth/:assessmentId(\\d+)/measurements/weight/",
    name: "MeasurementWeight",
    component: MeasurementWeight,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/growth/:assessmentId(\\d+)/measurements/length/",
    name: "MeasurementLength",
    component: MeasurementLength,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/growth/:assessmentId(\\d+)/measurements/head-circumference/",
    name: "MeasurementHeadSize",
    component: MeasurementHeadSize,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/growth/:assessmentId(\\d+)/measurements/arm-size/",
    name: "MeasurementMuac",
    component: MeasurementMuac,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/growth/:assessmentId(\\d+)/recommendations/",
    name: "GrowthRecommendations",
    component: GrowthRecommendations,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/growth/:assessmentId(\\d+)/recommendations/advanced/",
    name: "GrowthRecommendationsAdvanced",
    component: GrowthRecommendationsAdvanced,
    beforeEnter: checkSession,
  },

  // Anemia
  {
    path: "/site/:siteCmiId(\\d+)/children/needing-anemia",
    name: "NeedAnemiaAssessmentList",
    component: NeedAnemiaAssessmentList,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/anemia/:assessmentId(\\d+)?/",
    name: "AnemiaPreface",
    component: AnemiaPreface,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/anemia/:assessmentId(\\d+)/2/",
    name: "AnemiaHemoglobinForm",
    component: AnemiaHemoglobinForm,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/anemia/:assessmentId(\\d+)/recommendations/",
    name: "AnemiaRecommendations",
    component: AnemiaRecommendations,
    beforeEnter: checkSession,
  },

  // Feeding screening
  {
    path: "/site/:siteCmiId(\\d+)/children/needing-feeding-screening",
    name: "NeedFeedingScreeningList",
    component: NeedFeedingScreeningList,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/feeding-screening/:assessmentId(\\d+)?/",
    name: "FeedingScreeningDateOfAssessment",
    component: FeedingScreeningDateOfAssessment,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/feeding-screening/:assessmentId(\\d+)?/notes/",
    name: "FeedingScreeningNotes",
    component: FeedingScreeningNotes,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/feeding-screening/:assessmentId(\\d+)?/screening/",
    name: "FeedingScreening",
    component: FeedingScreening,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/feeding-screening/:assessmentId(\\d+)?/recommendations/",
    name: "FeedingScreeningRecommendations",
    component: FeedingScreeningRecommendations,
    beforeEnter: checkSession,
  },

  // Best Practices
  {
    path: "/child/:childId(\\d+)/practices/:assessmentId(\\d+)?/",
    name: "PracticesDateOfAssessment",
    component: PracticesDateOfAssessment,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/practices/:assessmentId(\\d+)/notes/",
    name: "PracticesNotesForm",
    component: PracticesNotesForm,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/practices/:assessmentId(\\d+)/tools/",
    name: "PracticesToolsForm",
    component: PracticesToolsForm,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/practices/:assessmentId(\\d+)/textures/",
    name: "PracticesTexturesForm",
    component: PracticesTexturesForm,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/practices/:assessmentId(\\d+)/feeder/",
    name: "PracticesFeederForm",
    component: PracticesFeederForm,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/practices/:assessmentId(\\d+)/forms/",
    name: "PracticesFormMenu",
    component: PracticesFormMenu,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/practices/:assessmentId(\\d+)/bf/",
    name: "BreastfeedingForm",
    component: BreastfeedingForm,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/practices/:assessmentId(\\d+)/rf/",
    name: "ResponsiveFeedingForm",
    component: ResponsiveFeedingForm,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/practices/:assessmentId(\\d+)/fp/",
    name: "OtherFeedingPracticesForm",
    component: OtherFeedingPracticesForm,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/practices/:assessmentId(\\d+)/diet/",
    name: "DietQualityForm",
    component: DietQualityForm,
    beforeEnter: checkSession,
  },
  {
    path: "/child/:childId(\\d+)/practices/:assessmentId(\\d+)/recommendations/",
    name: "PracticesRecommendations",
    component: PracticesRecommendations,
    beforeEnter: checkSession,
  },
  {
    path: "/site/:siteCmiId(\\d+)/children/needing-best-practices/",
    name: "NeedBestPracticesList",
    component: NeedBestPracticesList,
    beforeEnter: checkSession,
  },

  // Documentation/help
  {
    path: "/help/",
    name: "DocumentationHome",
    component: DocumentationHome,
  },
  {
    path: "/help/what-is-cmi-plus",
    name: "DocumentationWhatIsCmiPlus",
    component: DocumentationWhatIsCmiPlus,
  },
  {
    path: "/help/about-offline",
    name: "DocumentationOfflineUsageAbout",
    component: DocumentationOfflineUsageAbout,
  },
  {
    path: "/help/prepare-for-offline",
    name: "DocumentationOfflineUsagePrepare",
    component: DocumentationOfflineUsagePrepare,
  },
  {
    path: "/reports/",
    name: "AppLevelDemographicReport",
    component: DemographicReport,
    beforeEnter: [connectivityRequired, checkSession],
  },
  {
    path: "/project/:projectCmiId(\\d+)/reports/",
    name: "ProjectLevelDemographicReport",
    component: DemographicReport,
    beforeEnter: [connectivityRequired, checkSession],
  },
  {
    path: "/site/:siteCmiId(\\d+)/reports/",
    name: "SiteLevelDemographicReport",
    component: DemographicReport,
    beforeEnter: [connectivityRequired, checkSession],
  },
  {
    path: "/reports/malnutrition/",
    name: "AppLevelMalnutritionReport",
    component: MalnutritionReport,
    beforeEnter: [connectivityRequired, checkSession],
  },
  {
    path: "/project/:projectCmiId(\\d+)/reports/malnutrition/",
    name: "ProjectLevelMalnutritionReport",
    component: MalnutritionReport,
    beforeEnter: [connectivityRequired, checkSession],
  },
  {
    path: "/site/:siteCmiId(\\d+)/reports/malnutrition/",
    name: "SiteLevelMalnutritionReport",
    component: MalnutritionReport,
    beforeEnter: [connectivityRequired, checkSession],
  },

  // Site Visit Reports
  {
    path: "/site/:siteCmiId(\\d+)/visits/",
    name: "SiteVisitReportListForSite",
    component: SiteVisitReportListForSite,
    beforeEnter: [checkSession],
  },
  {
    path: "/project/:projectCmiId(\\d+)/visits/",
    name: "SiteVisitReportListForProject",
    component: SiteVisitReportListForProject,
    beforeEnter: [checkSession],
  },
  {
    path: "/site/:siteCmiId(\\d+)/visits/new",
    name: "SiteVisitReportCreate",
    component: SiteVisitReportCreateOrUpdate,
    beforeEnter: [checkSession],
  },
  {
    path: "/site/:siteCmiId(\\d+)/visits/:reportId(\\d+)/edit",
    name: "SiteVisitReportUpdate",
    component: SiteVisitReportCreateOrUpdate,
    beforeEnter: [checkSession],
  },
  {
    path: "/site/:siteCmiId(\\d+)/visits/:reportId(\\d+)/",
    name: "SiteVisitReportDetail",
    component: SiteVisitReportDetail,
    beforeEnter: [checkSession],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

// Determine transition animation class. If it seems clear we're going deeper or less deep,
// make it a directional animation. Otherwise, just use the fade.
// Special carve out for our Intro Slides, which are also directional.
router.afterEach((to, from) => {
  // Are we in the Intro sequence?
  if (to?.name?.startsWith("IntroSlide") || from?.name?.startsWith("IntroSlide")) {
    if (to?.name?.startsWith("IntroSlide") && from?.name?.startsWith("IntroSlide")) {
      to.meta.transition = to.name < from.name ? "slide-left" : "slide-right"
      return
    }
    else {
      to.meta.transition = "fade"
      return
    }
  }
  // Just go on perceived depth in app structure.
  const toDepth = to.path.split('/').length
  const fromDepth = from.path.split('/').length
  to.meta.transition = toDepth === fromDepth ? "fade" : toDepth < fromDepth ? "slide-left" : "slide-right"
})

export default router
