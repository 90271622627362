<template>
  <div id="back-button">
    <button
      type="button"
      class="captioned-icon"
      :ariaLabel="$gettext('Go back')"
      @click="goBack"
    >
      <IconLeftArrow />
    </button>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router"

import IconLeftArrow from "@/components/svg/IconLeftArrow.vue"
const router = useRouter()

function goBack() {
  return router.back()
}
</script>

<style scoped>
svg {
  stroke: white;
  fill: white;
}
</style>
