<script setup>
import { ref, watchEffect } from "vue"

import { getFlashMessages } from "@/utils/GlobalState"
import UniqueID from "@/utils/UniqueID"

const flashMessages = getFlashMessages()
const currentMessages = ref([])
const containerElement = ref(null)

watchEffect(() => {
  // Pull messages from our global object so they only show once.
  while (flashMessages.length) {
    let msg = flashMessages.shift()
    msg.id = "fm-" + UniqueID()
    currentMessages.value.push(msg)
    // Animate (and delay) the message's appearance, to subtly draw attention to it.
    setTimeout(
      () => {
        const wrapperElement = containerElement.value.querySelector(`#${msg.id}`)
        const contentElement = wrapperElement.querySelector(".notification")
        const expandedHeight = contentElement.getBoundingClientRect().height
        const animation = wrapperElement.animate(
          [
            { height: "0px", opacity: 0 },
            { height: `${expandedHeight}px`, opacity: 1 },
          ],
          {
            easing: "ease-in-out",
            duration: 500,
            iterations: 1,
            fill: "forwards",
          },
        )
        animation.onfinish = () => document.getElementById("flash-messages")?.scrollIntoView({ behavior: "smooth" })
        // Set up message's self-destruction. (duration of 0 means don't self-destruct)
        if (msg.duration) {
          setTimeout(() => {
            destroyMessage(msg.id)
          }, msg.duration)
        }
      },
      msg.appearanceDelay, // delay animation slightly, so it doesn't overlap too much with page load.
    )
  }
})

// Animate the removal of a specific flash message
function destroyMessage(id) {
  const wrapperElement = containerElement.value?.querySelector(`#${id}`)
  // If the user manually dismissed the alert (or the container is otherwise missing),
  // our work is done.
  if (!wrapperElement) {
    return
  }
  const contentElement = wrapperElement.querySelector(".notification")
  const expandedHeight = contentElement.getBoundingClientRect().height
  wrapperElement.animate(
    [
      { height: `${expandedHeight}px`, opacity: 1 },
      { height: "0px", opacity: 0 },
    ],
    {
      easing: "ease-in-out",
      duration: 500,
      iterations: 1,
      fill: "forwards",
    },
  )
  // Delay the actual destruction of the object until the animation finishes
  setTimeout(() => {
    for (let i = 0; i < currentMessages.value.length; i++) {
      if (currentMessages.value[i].id == id) {
        currentMessages.value.splice(i, 1)
      }
    }
  }, 500)
}
</script>

<template>
  <div
    v-if="currentMessages"
    id="flash-messages"
    ref="containerElement"
  >
    <div
      v-for="message in currentMessages"
      :id="message.id"
      :key="message.id"
      ref="messageRefs"
      style="height: 0; overflow: hidden; opacity: 0; margin-bottom: 1em"
    >
      <div :class="['notification', message.class]">
        <button
          :id="message.id"
          class="delete"
          @click="destroyMessage(message.id)"
        />
        <p v-html="message.msg" />
      </div>
    </div>
  </div>
</template>

<style scoped>
  .notification p:last-of-type {
    margin-bottom: 0;
  }
</style>
