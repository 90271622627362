<template>
  <div
    v-if="inTrainingMode"
    class="training-mode-banner"
  >
    <strong>
      {{ $gettext("In Training Mode") }}
    </strong>
  </div>
</template>
<script setup>
  import { ref } from "vue"
  import { useCurrentViewContextStore } from "@/utils/GlobalState"

  const currentViewContextStore = useCurrentViewContextStore()
  const inTrainingMode = ref(false)
  
  currentViewContextStore.$subscribe(async () => {
    inTrainingMode.value = await currentViewContextStore.getTrainingMode()
  })
</script>
