<template>
  <header class="has-background-primary">
    <!-- This is a centered Count Me In logo (or text equivalent). Not shown on mobile. -->
    <a
      id="faux-logo"
      href="/"
    >
      <LogoLarge />
    </a>

    <!-- The main nav bar. -->
    <div id="nav-bar">
      <BackButton />
      <div id="nav-bar-title">
        {{ navBarTitle }}
      </div>
      <div id="right-menu">
        <template v-if="isLoggedIn">
          <!-- Combined hamburger menu dropdown -->
          <div class="dropdown is-right is-hoverable">
            <div class="dropdown-trigger">
              <a
                class="captioned-icon"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
              >
                <div class="navbar-burger">
                  <span aria-hidden="true" />
                  <span aria-hidden="true" />
                  <span aria-hidden="true" />
                </div>
                <span class="icon-caption">
                  {{ $gettext("Menu") }}
                </span>
              </a>
            </div>
            <div
              id="dropdown-menu"
              class="dropdown-menu"
              role="menu"
            >
              <div class="dropdown-content">
                <div class="menu-section-label">
                  {{ $gettext("Account") }}
                </div>
                <AccountMenu />
                <div class="menu-section-label">
                  {{ $gettext("Help") }}
                </div>
                <HelpMenu />
              </div>
            </div>
          </div>
        </template>
        <router-link
          v-else
          class="account"
          :to="{ name: 'Login' }"
        >
          Log in
        </router-link>
      </div>
    </div>
  </header>
</template>
<script lang="ts" setup>
import { ref, onMounted } from "vue"
import { useRouter } from "vue-router"

import { useCurrentViewContextStore, isUserLoggedIn } from "@/utils/GlobalState"
import { pauseSession, getSessionStatus, shouldUseSessionLocking } from "@/utils/Session"
import { logout } from "@/services/Account"

import LogoLarge from "@/components/svg/LogoLarge.vue"
import BackButton from "@/components/BackButton.vue"
import AccountMenu from "@/components/accounts/AccountMenu.vue"
import HelpMenu from "@/components/HelpMenu.vue"

const navBarTitle = ref("")
const isLoggedIn = isUserLoggedIn()
const currentViewContextStore = useCurrentViewContextStore()
const router = useRouter()

currentViewContextStore.$subscribe(() => {
  navBarTitle.value = currentViewContextStore.getNavBarTitle()
})

// Logic for checking user's "session" status. (Don't check on local dev
// bc just flipping to dev tools triggers blur!)
onMounted(() => {
  if (shouldUseSessionLocking) {
    window.addEventListener("blur", () => {
      // Only change things if we had been in a live session
      if (getSessionStatus() === "l") {
        try {
          pauseSession()
        }
        catch {
          // Any error means just nuke it and log them out.
          logout(true)  // async, but we don't care about the return value
          return router.push({ name: "Login" })
        }
        router.push({ name: "ReenterPinForm" })
      }
    })
  }
})
</script>

<style scoped>
.navbar-burger {
  height: 1.5rem;
}
</style>
