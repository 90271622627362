<template>
  <router-link
    :to="{ name: 'DocumentationHome' }"
    class="dropdown-item"
  >
    {{ $gettext("Documentation") }}
  </router-link>
  <router-link
    :to="{ name: 'FeedbackForm' }"
    class="dropdown-item"
  >
    {{ $gettext("Provide feedback") }}
  </router-link>
</template>