<!-- From https://www.iconfinder.com/icons/352504/library_local_icon -->
<template>
  <svg
    aria-hidden="true"
    class="icon-book"
    height="100%"
    width="100%"
    viewBox="0 0 48 48"
    version="1.1"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M0 0h48v48h-48z"
      fill="none"
    />
    <path d="M24 23.09c-4.73-4.39-11.04-7.09-18-7.09v22c6.96 0 13.27 2.7 18 7.09 4.73-4.39 11.04-7.09 18-7.09v-22c-6.96 0-13.27 2.7-18 7.09zm0-7.09c3.31 0 6-2.69 6-6s-2.69-6-6-6-6 2.69-6 6 2.69 6 6 6z" />
  </svg>
</template>
