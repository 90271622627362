<!-- From https://fonts.google.com/icons?selected=Material%20Symbols%20Outlined%3Ahome%3AFILL%400%3Bwght%40400%3BGRAD%400%3Bopsz%4024 -->
<template>
  <svg
    height="100%"
    width="100%"
    class="icon-home"
    viewBox="0 -960 960 960"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z" />
  </svg>
</template>
