import { FormKitOptions } from '@formkit/core'
import { createI18nPlugin, en, es, fr, vi } from '@formkit/i18n'
import { createThemePlugin } from '@formkit/themes'
import { eye, eyeClosed } from '@formkit/icons'

import {
  checkbox,
  createLibraryPlugin,
  date,
  email,
  form,
  hidden,
  number,
  password,
  radio,
  select,
  submit,
  tel,
  text,
  textarea,
  time,
} from '@formkit/inputs'
import { accepted, confirm, date_after, date_before, required } from '@formkit/rules'
import { createValidationPlugin } from '@formkit/validation'
import { bindings } from '@formkit/vue'

const library = createLibraryPlugin({
  form, checkbox, select, submit, text, radio, date, number, email,
  password, hidden, tel, textarea, time,
})
const i18n = createI18nPlugin({ en, fr, es, vi })
const validation = createValidationPlugin({ required, accepted, confirm, date_before, date_after })
const icons = { eye, eyeClosed }
const theme = createThemePlugin(null, icons)

const plugins = [
  library,
  bindings,
  i18n,
  validation,
  theme,
]

export const formkitConfig = { plugins } as FormKitOptions
